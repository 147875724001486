import { Commitment } from './../../commitments/commitment/commitment';
export class PaymentsPlan {
    paymentsPlanID: number;
    contactID: number;
    commitmentID: number;
    globalAmount: number;
    pulseAmount?: number;
    hebrewDate: boolean;


    commitmentSubject: number;
    payPlanCommitments: any[];
    holdingsSettingID: number;
}

// export class ReHoldingsModel {
//     holdingAmount?: number;
//     holdingSpecialtyMonthsAmount?: number;
//     holdingFromMonth: number;
//     holdingFromYear: number;
//     holdingCurrency: number;
//     reOldHoldings: boolean;
// 	paymentsPlanID: number;
// }

export class HoldingsSetting {
    createBy; createDate; changeBy; changeDate;
    holdingsSettingID: number = 0;
    holdingFromMonth;
    holdingFromYear;
    holdingAmount;
    holdingSpecialtyMonthsAmount;
    holdingCurrency;
    holdingContactIsDied;
    lastHoldingRecordOn;
    contactID;
    contactName: string;
    holdingContactID;
    holdingContactName: string;
    holdingContactRelationTypeID;
    holdingContactRelationTypeName: string;
    reOldHoldings: boolean = false;
    byMonthlyPayments: boolean = true;
    paymentsPlanID: number;
}


export class PayPlanCommitment {

    payPlanCommitmentID: number;
    paymentsPlanID: number;
    commitmentID: number;
    partialPulseAmount;
    partialSpecialtyMonthsAmount;
    designation;
    designationDetails;
    contactID;
    registrationID;
    honorID;
    commitmentReasonID;
    commitmentReasonName;
    commitmentSubject;
    commitmentSubjectName;
    hebYear;
    operation;
}


export class ClosedPaymentsPlanMdl {
    paymentsPlanID: number
    closedComment: string;
    closedDate;
    closedBy;
    closedStatus: number;
}
