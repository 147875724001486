<mat-toolbar matDialogTitle class="mat-accent m-0">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
    <h1 mat-dialog-title>מיזוג אנשי קשר</h1>
  </mat-toolbar-row>
</mat-toolbar>
<div mat-dialog-content *ngIf="formData" [formGroup]="formData">
  <div fxLayout="column" class="form-vertical">
    <auto-complete apply-enter [placeholder]="'איש קשר שאליו יעברו הנתונים'" [controllerName]="'Contacts'"
      [formGroupModel]="formData" [idControl]="formData.controls.newContactID"
      [nameControl]="formData.controls.newContactName">
    </auto-complete>
    &nbsp;&nbsp;
    <div class="input-group mb-3">
      <label>מחיקת איש קשר...</label>
      <mat-select class="form-control" #activSelect placeholder="מחיקת איש קשר..." formControlName="doDeleteCurrentOldContact">
        <mat-select-trigger>
          <span style="color: green;" *ngIf="activSelect.value != true">לא למחוק איש קשר נוכחי לאחר השלמת הפעולה</span>
          <span style="color: red;" *ngIf="activSelect.value == true">למחוק איש קשר נוכחי לאחר השלמת הפעולה</span>
        </mat-select-trigger>
        <mat-option style="color: green;" [value]="false">לא למחוק איש קשר נוכחי לאחר השלמת הפעולה</mat-option>
        <mat-option style="color: red;" [value]="true">למחוק איש קשר נוכחי לאחר השלמת הפעולה</mat-option>
      </mat-select>
    </div>

  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="onNoClick()">ביטול</button>
    <button style="background-color: red;" mat-button [mat-dialog-close]="formData">מזג</button>
  </div>
</div>
