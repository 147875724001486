import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { ContactsData } from 'src/app/contacts/contacts.data';
import { ServicesData } from 'src/app/services/services.data';




@Component({
    templateUrl: './contact-merge-contacts-dlg.tmpl.html'
})

export class ContactMergeContactsDlg implements OnInit {
    public formData: FormGroup;

    constructor(
        public matDialogRef: MatDialogRef<ContactMergeContactsDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any, //{contactID , contactName}
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        public contactsData: ContactsData
    ) { }


    ngOnInit(): void {
        this.initForm();
    }


    private initForm(): void {
        this.formData = this.formBuilder.group({
          currentOldContactID: [this.data.contactID, Validators.required],
          currentOldContactName: [this.data.contactName, Validators.required],
          newContactID: [null, Validators.required],
          newContactName: [],
          doDeleteCurrentOldContact: [null, Validators.required],
        });
    }


    onNoClick(): void {
        this.matDialogRef.close();
    }

    // public onSubmit(): void {
    //     if (this.isValid()) {
    //         this.contactsData.mergeContacts(this.formData.value).subscribe((result: any) => {
    //             this.matDialogRef.close(result);
    //         });
    //     }
    // }

    // public isValid(): boolean {
    //     return (this.formData && this.formData.valid);
    // }
}

