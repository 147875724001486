import { ContactReciptsDlg } from '../contacts-recipts-dlg.cmp';
import { ContactReportModelDlg } from '../contactReports/contact-report-model-dlg.cmp';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { ContactsData } from '../contacts.data';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Contact, ContactGroup, ContactEvent, ContactReciptsMdl } from './contact';
import { saveAs } from 'file-saver-es';
import { NavItem } from 'src/app/components/menu-list-item/nav-item';
import { ContactAppointment } from '../contactAppointments/contactAppointment/contactAppointment';
import { environment } from 'src/environments/environment';
import { HoldingsExlsDlg } from 'src/app/holdings/holdings-exls-dlg.cmp';
import { AchasIledorosDlg } from './achas-iledoros/achas-iledoros-dlg.cmp';
import { ShortYearInfoDlg } from '../contactReports/short-year-info/short-year-info-dlg.com';
import { GiteZmanimDlg } from './gite-zmanim/gite-zmanim-dlg.cmp';
import { ContactMergeContactsDlg } from './contact-merge-contacts-dlg.cmp';


@Component({
  selector: 'contact-details',
  templateUrl: './contact-details.tmpl.html',
  styleUrls: ['./contact-edit.scss'],
  encapsulation: ViewEncapsulation.None
})


export class ContactDetails implements OnInit {
  constructor(
    private contactsData: ContactsData,
    private activatedRoute: ActivatedRoute,
    private toraxHttp: ToraxHttp,
    private cdref: ChangeDetectorRef,
    private location: Location,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.url = environment.apiUrl;
    this.toraxHttp.updatedEvent.subscribe((obj: any) => {
      if (obj.id > 0 && this.contact && obj.id === this.contact.contactID) {
        this.loadContact();
        this.cdref.detectChanges();
      }
    });
  }

  url;
  rerender = false;
  @ViewChild('tabs', { static: true }) tabs: MatTabGroup;
  @ViewChild('childMenu') public childMenu;

  responsive = true;
  cols = 1;
  val = false;
  contact: Contact;
  id: number;
  codeIchud: number;
  contactGroups: ContactGroup[]; //
  contactEvents: ContactEvent[]; //
  contactAppointments: ContactAppointment[]; //
  aIData: any;
  gZInfo: any;

  public loadContact(): void {
    if (this.codeIchud) {
      this.contactsData.getByIchud(this.codeIchud)
        .subscribe((c: any) => {
          this.contact = c;
          this.contactGroups = c.contactGroups;
          this.contactEvents = c.contactEvents;
          this.contactAppointments = c.contactAppointments;
          this.id = this.contact.contactID;
          this.codeIchud = this.contact.codeIchud;
          this.location.replaceState(`contacts/${this.id}`);

          this.rerender = true;
          this.cdref.detectChanges();
          this.rerender = false;
        });
    } else {
      this.contactsData.get(this.id)
        .subscribe((c: any) => {
          this.contact = c;
          this.contactGroups = c.contactGroups;
          this.contactEvents = c.contactEvents;
          this.contactAppointments = c.contactAppointments;
          this.id = this.contact.contactID;
          this.codeIchud = this.contact.codeIchud;

          this.rerender = true;
          this.cdref.detectChanges();
          this.rerender = false;
        });
    }
    this.loadAchasIledoros();
    this.loadGiteZmanimInfo();
  }

  loadAchasIledoros() {
    this.contactsData.getAiData(this.id)
      .subscribe((c: any) => {
        this.aIData = c;
      });
  }
  loadGiteZmanimInfo() {
    this.contactsData.getGiteZmanimInfo(this.id)
      .subscribe((c: any) => {
        this.gZInfo = c;
        console.log(this.gZInfo);
      });
  }

  viewShortInfoByYear() {
    const dialogRef = this.dialog.open(ShortYearInfoDlg, {
      data: { contactID: this.id, year: 'תשפד', contactName: `${this.contact.firstName} ${this.contact.lastName}` },
    });

  }



  openMergeContactDlg() {
    const dialogRef = this.dialog.open(ContactMergeContactsDlg, {
      data: { contactID: this.id, contactName: `${this.contact.firstName} ${this.contact.lastName}` }
    });



    dialogRef.beforeClosed().subscribe(result => {

      if (result && result.valid) {
        const alrtResult = prompt('האם אתה בטוח שאתה רוצה למזג?\nכדי לאשר את הפעולה הזן בתיבה את מזהה האיש הנוכחי להעברה/מחיקה\nאחרת לחץ על ביטול');
        console.log('formData-value', result.value);
        console.log('alrtResult', alrtResult);
        console.log('currentOldContactID', result.value.currentOldContactID);
        if (alrtResult != null && alrtResult == result.value.currentOldContactID) {
          if (result && result.value) {
            this.contactsData.mergeContacts(result.value).subscribe((result: any) => {
              dialogRef.close(result);
            });
          }
        }
      }
    });
  }


  openReciptsForContactDlg() {
    let contactReciptsMdl = new ContactReciptsMdl();
    contactReciptsMdl.contactID = this.id;
    contactReciptsMdl.mergePayments = true;

    //let mdc = new MatDialogConfig<any>();
    //mdc.data = this.id;
    const dialogRef = this.dialog.open(ContactReciptsDlg, {
      data: this.id,
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.value) {
        let cid = result.value.contactID;
        let paymentsIds = result.value.paymentsIds;
        let mergePayments = result.value.mergePayments;
        this.contactsData.createAndPrintReceiptsForAnyPayments(cid, mergePayments, paymentsIds)
          .subscribe((c: any) => {
            let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no, onload="window.print()"');
            popupWinindow.document.open();
            popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: A4; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>c).value + '</div></body></html>');
            popupWinindow.document.close();
            return true;
            //this.matDialogRef.close();
          });
      }
    });
  }



  createAndrintNewReceiptsForContact(): void {
    this.printNewReceiptsForContact(true);
  }

  public printNewReceiptsForContact(andCreate = false): void {
    this.contactsData.printNewReceiptsForContact(this.id, andCreate)
      .subscribe(
        data => {
          //     if (blob !== '') {
          // tslint:disable-next-line:max-line-length
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          // tslint:disable-next-line:max-line-length
          popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
          popupWinindow.document.close();
        }
      )
  }


  selectedCommitmentsRowsChange($event) {
    this.selectedCommitmentsIDs = $event.map(function (e) {
      return e.commitmentID;
    });
  }


  selectedCommitmentsIDs;
  public getContactShortInformation(): void {
    // let doDownload = true;
    const dialogRef = this.dialog.open(ContactReportModelDlg, {
      data: {
        contactID: this.id,
        contactName: `${this.contact.firstName} ${this.contact.lastName}`,
        selectedCommitmentsIDs: this.selectedCommitmentsIDs
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result && result.valid) {
        this.dowloand(result.value);
      }
    });

  }
  dowloand(result: any) {
    this.contactsData.getContactShortInformation(result).subscribe((blob: any) => {
      var blb = new Blob([blob], { type: "application/pdf;charset=utf-8" });
      saveAs(blb, `דוח ${this.contact.perfix} ${this.contact.firstName} ${this.contact.lastName} ${this.contact.suffix}.pdf`);
    });
  }

  public printAllContactInformation(): void {
    let doDownload = true;
    this.contactsData.printAllContactInformation(this.id).subscribe((blob: any) => {
      var blb = new Blob([blob], { type: "application/pdf;charset=utf-8" });
      saveAs(blb, `דוח ${this.contact.perfix} ${this.contact.firstName} ${this.contact.lastName} ${this.contact.suffix}.pdf`);
    });

  }


  openHoldingsExlsDlg(): void {
    const dialogRef = this.dialog.open(HoldingsExlsDlg, {
      data: { contactID: this.id, contactName: this.contact.contactName }
      // width: '80%'
    });
  }

  public printEnvelopeorContact(): void {
    this.contactsData.printEnvelopeorContact(this.id)
      .subscribe(
        data => {
          // tslint:disable-next-line:max-line-length
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          // tslint:disable-next-line:max-line-length
          popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page {size: 110mm 230mm;}@media (max-width: 230mm) {@page { size: 110mm 230mm;}} </style></head><body  onload="window.print()"><style>@page {size: 110mm 230mm;} @media (max-width: 230mm) { @page { size: 110mm 230mm;}} @media print {@page { size: 110mm 230mm;}} </style><div>' + (<any>data).value + '</div></body></html>');
          popupWinindow.document.close();
        }
      )
  };

  openAchasIledorosDlg(): void {
    if (this.aIData) {
      const dialogRef = this.dialog.open(AchasIledorosDlg, {
        data: this.aIData,
        // width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { this.loadAchasIledoros(); });
    }
  }
  openGiteZmanimDlg(): void {
    const dialogRef = this.dialog.open(GiteZmanimDlg, {
      data: { contactID: this.id, projectName: 'גוטע זמנים', firstName: this.contact.firstName, lastName: this.contact.lastName, fatherName: this.contact.fatherName, cityName: this.contact.cityName, shtibelName: this.contact.shtibelName },
      // width: '60%'
    });
    dialogRef.afterClosed().subscribe(result => { this.loadGiteZmanimInfo(); });

  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = + params['contactID'];
      this.codeIchud = + params['codeIchud'];
      this.loadContact();
      this.activeTabIndex = sessionStorage.getItem(`${this.pathStr}`) || 0;

    });
  }
  get pathStr() {
    return `${this.perfix}${this.location.path()}`;
  }


  activeTabIndex;
  perfix = "tabs_";
  public itemSelect(event) {
    this.activeTabIndex = 're'
    this.activeTabIndex = event.index;
    sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
    this.cdref.detectChanges();
  }


}
