import { saveAs } from 'file-saver-es';

import { CommitmentDetailsDlg } from './commitment/commitment-details-dlg.cmp';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { AlertService } from '../components/alert/alert.svc';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommitmentsData } from './commitments.data';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { IndexService } from '../services/index-service';
import { SubscriptionLike } from 'rxjs';



@Component({
  selector: 'commitments-list',
  templateUrl: 'commitments-list.tmpl.html'
})



export class CommitmentsList implements OnInit, OnDestroy {

  constructor(
    private commitmentsData: CommitmentsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    public toraxHttp: ToraxHttp,
    private activatedRoute: ActivatedRoute
  ) {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Android") > -1) {
      this.androidMode = true;
    }

    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/commitments') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }
  androidMode: boolean = false;
  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  options = {
    //	timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true
  };
  indexService: IndexService;

  displayedColumns: string[] = ['select', 'buttons', 'commitmentSubject', 'commitmentReasonName', 'contactName', 'pulseAmount', 'globalAmount', 'qty', 'payMethodID', 'fromDate', 'tillDate', 'closedDate', 'closedStatusName', 'commitmentID'];
  data: any[] = [];
  groupingColumn = '';
  groupingOrderColumn = '';
  resultsLength = 0;
  isRateLimitReached = false;
  searchCommitmentsListForm: FormGroup;
  selectionAmount;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  selection = new SelectionModel<number>(true, []);
  commitmentReasons: any[];
  defaultvalues;
  selectedItems;

  actualMinusHeight = '185';

  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('contactID') public contactID?: number;
  @Input('commitmentID') public commitmentID?: number;
  @ViewChild('shtibelID', { static: true }) shtibelID: AutoCompleteComponent;
  @ViewChild('subjects', { static: true }) subjects: MatSelect
  @ViewChild('reasonIDs', { static: true }) reasonIDs: MatSelect
  @ViewChild('types', { static: true }) types: MatSelect
  @ViewChild('account', { static: true }) account: MatSelect
  @ViewChild('payMethodGroups', { static: true }) payMethodGroups: MatSelect
  @ViewChild('closedStatuses', { static: true }) closedStatuses: MatSelect

  @Output() selectedRowsChange = new EventEmitter<any>();

  toogleState() {
    this.panelOpenState = !this.panelOpenState;
    this.actualMinusHeight = this.panelOpenState ? '290' : '120';
  }

  private findStartEndIndices(): { startIndex: number, endIndex: number } {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    const total = this.paginator.length;
    const startIndex = pageIndex * pageSize;
    const endIndex = (startIndex + pageSize) > total ? total : startIndex + pageSize;
    return { startIndex: startIndex, endIndex: endIndex };
  }

  isAllSelected() {
    const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();
    const sortedData = this.sourceList._orderData(this.sourceList);
    const numSelected = sortedData.slice(page.startIndex, page.endIndex)
      .filter(row => {
        this.selection.isSelected(row);
      }
      ).length;

    return numSelected === (page.endIndex - page.startIndex);
  }

  isAtLeaseOneSelected() {
    const page: { startIndex: number, endIndex: number } =
      this.findStartEndIndices();
    const sortedData = this.sourceList._orderData(this.sourceList);
    const numSelected = sortedData.slice(page.startIndex, page.endIndex)
      .filter(row => {
        this.selection.isSelected(row);
      }).length;
    return numSelected > 0;
  }

  masterToggle() {
    const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();

    const sortedData = this.sourceList._orderData(this.sourceList);

    if (this.isAllSelected()) {
      sortedData.slice(page.startIndex, page.endIndex)
        .forEach(row => {
          this.selection.deselect(row);
        });
    } else {
      sortedData.slice(page.startIndex, page.endIndex).forEach(row => {
        this.selection.select(row);
      });
    }
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ['add'] } }]);
  }

  openSelectedRow(commitmentID) {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Android") > -1) {
      this.openDetails({commitmentID:commitmentID});
    } else{
      this.router.navigate(['', { outlets: { popup: ['commitments', commitmentID] } }]);
    }

  }

  openDetails(row) {
    this._matDialog.open(CommitmentDetailsDlg, {
      panelClass: 'commitment-details',
      data: row.commitmentID
    });
  }

  downloadOneCommitmentReport(row) {
    this.commitmentsData.downloadOneCommitmentReport(row.contactID, row.commitmentID).subscribe((blob: any) => {
      var blb = new Blob([blob], { type: "application/pdf;charset=utf-8" });
      saveAs(blb, `${row.contactName} - דוח התחייבות מס' ${row.commitmentID} - ${row.commitmentSubjectName}.pdf`);
    });
  }

  getCommitmentReasons() {
    this.servicesData.getList(`AllCommitmentReasons`)
      .subscribe(
        (c: any) => {
          this.commitmentReasons = c;
        },
        error => { });
  }

  public getCommitmentsReport(): void {
    this.commitmentsData.exportExcel(this.searchCommitmentsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח התחייבויות.xlsx`;
        link.click();
      });
  }

  public getSmallCommitmentsReport(): void {
    this.commitmentsData.exportSmallExcel(this.searchCommitmentsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח התחייבויות.xlsx`;
        link.click();
      });
  }

  changed(e) {
  }

  private initForm() {
    this.searchCommitmentsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      commitmentID: [],
      contactName: [],
      commitmentReasonIDs: [null],
      donationTypes: [null],
      fromDateRange: [null],
      tillDateRange: [null],
      closedDateRange: [null],
      globalAmountRange: [null],
      pulseAmountRange: [null],
      groupIdsToExludeContacts: [null],
      groupIdsToIncludeContacts: [null],
      cityIDs: [null],
      shtibelID: [null],
      shtibelName: [],
      friendsInStiebel:[null],
      withTillDate: [null],
      withRegistrations:[null],
      withHonors:[null],
      closedStatuses: [null],
      commitmentSubjects: [],
      fundraisingTypes: [null],
      commitmentIDs: [],
      activeCommitments:[this.contactID ? true : null],
      commitmentsToTreatment:[false, Validators.required],
      includeColumns:["ContactID,CodeIchud,Perfix,LastName,FirstName,Suffix,AddressTitle,CityName"],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchCommitmentsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.commitmentID));
      this.selectedRowsChange.emit(x.source.selected);
    });

    let frm = this.searchCommitmentsListForm;
    this.defaultvalues = Object.keys(this.searchCommitmentsListForm.value)
    .filter(key => frm.controls[key].validator == Validators.required)
    .reduce((obj, key) => {
      obj[key] = frm.controls[key].value;
      return obj;
    }, {});
    console.log("dv",this.defaultvalues );
  }


  panelOpenState = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  public referrerCmpUrl = "";

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.commitmentID === row.commitmentID);
    if (found) {
      return true;
    }
    return false;
  }

  ngOnInit() {

    this.initForm();
    this.activatedRoute.params.forEach((params: Params) => {
      let cs = +params['subject'];
      if(cs){
        this.searchCommitmentsListForm.controls["commitmentSubjects"].setValue([cs]);
        this.searchCommitmentsListForm.controls["commitmentsToTreatment"].setValue(true);

      }
    });


    if (this.toraxHttp.previousUrl == "/dashboard/posMode") {
      let item = sessionStorage.getItem("dashboard/commitments/dbCommitments");
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem("dashboard/commitments/dbCommitments") || "");
        this.searchCommitmentsListForm.patchValue(frm);
      }
    } else {
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchCommitmentsListForm.patchValue(frm);
      }
    }
    this.searchCommitmentsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      { key: "contactID", value: this.contactID },
    ];



    this.indexService = new IndexService(this.searchCommitmentsListForm, params, this.commitmentsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

    this.getCommitmentReasons();

    if (this.contactID) {
      this.actualMinusHeight = '362';
    }
  }

}
