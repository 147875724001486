﻿<div [formGroup]="searchCommitmentsListForm">

  <div class="multi-search" *ngIf="!contactID && panelOpenState">
    <div class="form-row" fxLayout="row">
      <date-input-range [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
        [dateRangeCtrl]="searchCommitmentsListForm.controls.fromDateRange">Loading...
      </date-input-range>
      &nbsp;&nbsp;
      <amount-input-range [placeHolderMin]="'מסכום כללי...'" [placeHolderMax]="'עד...'"
        [amountRangeCtrl]="searchCommitmentsListForm.controls.globalAmountRange">Loading...
      </amount-input-range>
      &nbsp;&nbsp;
      <amount-input-range [placeHolderMin]="'מסכום הוק...'" [placeHolderMax]="'עד...'"
        [amountRangeCtrl]="searchCommitmentsListForm.controls.pulseAmountRange">Loading...
      </amount-input-range>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>תאריך סיום...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="תאריך סיום..." formControlName="withTillDate">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">כולל</mat-option>
          <mat-option [value]="false">לא כולל</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;

      <mat-select-multiple-dropdown [keyProperty]="'commitmentReasonID'" [titleProperty]="'name'"
        *ngIf="commitmentReasons" (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.commitmentReasonIDs" [placeholder]="'סיבה...'"
        [list]="commitmentReasons">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>כיבודים ועליות...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="כיבודים ועליות..." formControlName="withHonors">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">כיבודים ועליות בלבד</mat-option>
          <mat-option [value]="false">ללא כיבודים ועליות</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>רישומים...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="רישומים..." formControlName="withRegistrations">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">רישומים בלבד</mat-option>
          <mat-option [value]="false">ללא רישומים</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
      <mat-select-multiple-dropdown [keyProperty]="'id'" [titleProperty]="'title'" *ngIf="servicesData.Cities"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.cityIDs" [placeholder]="'עיר...'"
        [list]="servicesData.Cities">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'" *ngIf="servicesData.DonationTypes"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.donationTypes" [placeholder]="'סוג...'"
        [list]="servicesData.DonationTypes">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        *ngIf="servicesData.FundraisingTypes" (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.fundraisingTypes" [placeholder]="'התחייבויות מקושרות...'"
        [list]="servicesData.FundraisingTypes">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <auto-complete apply-enter placeholder="שטיבל..." #shtibelID (onSelect)="indexService.selectionChanged($event)"
        [controllerName]="'Shtibels'" [formGroupModel]="searchCommitmentsListForm"
        [idControl]="searchCommitmentsListForm.controls.shtibelID"
        [nameControl]="searchCommitmentsListForm.controls.shtibelName"></auto-complete>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>שיוך לשטיבל...</label>
        <mat-select class="form-control" (selectionChange)="indexService.selectionChanged($event)"
          [disabled]="searchCommitmentsListForm.controls.shtibelID.value != null" placeholder="שיוך לשטיבל..."
          formControlName="friendsInStiebel">
          <mat-option [value]="">כולם</mat-option>
          <mat-option [value]="true">חברים בשטיבל</mat-option>
          <mat-option [value]="false">ללא שטיבל</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'" *ngIf="servicesData.ClosedStatuses"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.closedStatuses" [placeholder]="'סטטוס סגירת התחייבות...'"
        [list]="servicesData.ClosedStatuses">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;

      <mat-select-multiple-dropdown [keyProperty]="'groupID'" [titleProperty]="'groupTitle'"
        [colorProperty]="'groupColor'" *ngIf="servicesData.Groups"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.groupIdsToIncludeContacts" [placeholder]="'קבוצות...'"
        [list]="servicesData.Groups"></mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'groupID'" [titleProperty]="'groupTitle'"
        [colorProperty]="'groupColor'" *ngIf="servicesData.Groups"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchCommitmentsListForm.controls.groupIdsToExludeContacts"
        [placeholder]="'קבוצות לא מוכללות...'" [list]="servicesData.Groups">
      </mat-select-multiple-dropdown>

      <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
        <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="getCommitmentsReport()">
          <i class="fa fa-file-excel"></i></button> &nbsp;
        <button mat-mini-fab color="primary" title="ייצוא לאקסל מקוצר" (click)="getSmallCommitmentsReport()">
          <i class="fa fa-file-excel-o"></i></button>
      </div>
    </div>

  </div>

  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center" class="min-action-header">
    <span class="example-fill-remaining-space"></span>
    <div *ngIf="!contactID">
      <button class="btn" (click)="toogleState()">
        {{(panelOpenState ? 'הסתר' : 'חיפוש מורחב')}}
        <i *ngIf="!panelOpenState" class="fa fa-arrow-up"></i>
        <i *ngIf="panelOpenState" class="fa fa-arrow-down"></i>
      </button>
    </div>
    <div class="input-group mb-3" style="display: flex;">
      <i style="color: green; font-size: 30px; margin: auto;"
        *ngIf="searchCommitmentsListForm.controls.activeCommitments.value == true" class="fa fa-folder-open"></i>
      <i style="color:hwb(44 11% 8%); font-size: 30px; margin: auto;"
        *ngIf="searchCommitmentsListForm.controls.activeCommitments.value == false" class="fa fa-folder"></i>
      &nbsp;
      <mat-select class="form-control" placeholder="כל ההתחייבויות..." formControlName="activeCommitments">
        <mat-option [value]="">הכל</mat-option>
        <mat-option [value]="true" style="font-weight: bold; color: green; font-size: large;">התחייבויות
          פתוחות</mat-option>
        <mat-option [value]="false" style="font-weight: bold; color: hwb(44 11% 8%); font-size: large;">התחייבויות
          סגורות</mat-option>
      </mat-select>
    </div>

    <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'" [withLabel]="false"
      *ngIf="servicesData.CommitmentSubjects" (selectChange)="indexService.selectionChanged($event)"
      [listControl]="searchCommitmentsListForm.controls.commitmentSubjects" [placeholder]="'נושא...'"
      [list]="servicesData.CommitmentSubjects">
    </mat-select-multiple-dropdown>
  </div>
  <div style="text-align:left; white-space: nowrap;" class="example-button-row">
    <button mat-mini-fab class="mini" color="primary" title="סנן" (click)="indexService.getList()"> <i
        class="fa fa-filter"></i></button> &nbsp;
    <button mat-mini-fab class="mini" color="primary" title="נקה סינונים" (click)="indexService.clerFilter()">
      <i class="material-icons">clear</i></button>
  </div>
</div>

<div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
  <div fxFlex>
    <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
      [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
      <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
        [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
        matSort matSortDisableClear matSortDirection="asc">

        <ng-container matColumnDef="select">
          <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
            <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
              *ngIf="selection?.selected?.length > 0">{{
              selection?.selected?.length }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox apply-enter (click)="$event.stopPropagation()"
              (selectChange)="indexService.$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
          <mat-cell *matCellDef="let row">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item (click)="openDetails(row);">
                <mat-icon>remove_red_eye</mat-icon>
                <span>תצוגה מהירה</span>
              </a>

              <a *ngIf="androidMode" mat-menu-item [routerLink]="[ '/contacts', row.contactID]">
                <mat-icon> contacts</mat-icon>
                <span>כרטיס איש</span>
              </a>


              <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentID]}}]">
                <mat-icon>pageview</mat-icon>
                <span>פרטים מלאים</span>
              </a>
              <a mat-menu-item (click)="downloadOneCommitmentReport(row)">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>הורד דו"ח</span>
              </a>

              <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentID, 'edit']}}]">
                <mat-icon>edit</mat-icon>
                <span>עריכה</span>
              </a>
              <a mat-menu-item [disabled]="row.closedDate"
                [routerLink]="['', {outlets: {popup: ['commitments', row.commitmentID, 'paymentsPlans', 'add']}}]">
                <mat-icon>playlist_add</mat-icon>
                <span>הוסף תוכנית תשלום</span>
              </a>

              <a mat-menu-item [disabled]="row.closedDate"
                [routerLink]="['', {outlets: {popup: ['commitments', row.commitmentID, 'payments', 'add']}}]">
                <mat-icon>payment</mat-icon>
                <span>הוסף תשלום</span>
              </a>


              <a mat-menu-item>
                <mat-icon>information</mat-icon>
                <model-info style="padding: 4px" [dataProvider]="commitmentsData" [id]="row.commitmentID"
                  [title]="'מידע רשומה'">
                </model-info>
              </a>
            </mat-menu>

          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="commitmentID">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>
            <a [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentID]}}]">
              {{ row.commitmentID }}
              <i style="color:#1bd453" matTooltip="התחייבות לגיוס" class="fa fa-share-alt-square"
                *ngIf="row.isFundraising"></i>
              <i *ngIf="!row.isFundraising">&nbsp;&nbsp;&nbsp;</i>
            </a>
            <a style="color:#304cf1" *ngIf="row.commitmentFundraisingID > 0"
              [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentFundraisingID]}}]">
              {{ row.commitmentFundraisingID }}
              <i matTooltip="התחייבות מקושרת" class="fa fa fa-link"></i>
            </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="commitmentSubject">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> תיאור
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תיאור" fxHide fxShow.gt-xs>
            <b>{{ row.subjectName }}</b> - {{row.donationTypeName}} {{ row.hebYear }}
            &nbsp;
            <i *ngIf="row.isFundraising" style="color:#1bd453" matTooltip="התחייבות לגיוס"
              class="fa fa-share-alt-square"></i>
            &nbsp;
            <i *ngIf="row.commitmentFundraisingID > 0" style="color:#304cf1" matTooltip="התחייבות מקושרת"
              class="fa fa-link"></i>
            <!--
            <a style="color: blue" *ngIf="row.commitmentFundraisingID > 0"
              [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentFundraisingID]}}]">
              <i class="fa fa-link"></i> &nbsp; {{ row.commitmentFundraisingID }}
            </a> -->
            <br *ngIf="row.registrationEventTitle">
            <span *ngIf="row.registrationEventTitle">{{ row.registrationEventTitle }}</span>
            <br *ngIf="row.honorTypeDescription">
            <span *ngIf="row.honorTypeDescription">{{ row.honorTypeDescription }} {{ row.honorsSetDescription }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contactName">
          <mat-header-cell *matHeaderCellDef> שם איש </mat-header-cell>
          <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">

            <a *ngIf="!androidMode; else cnTtle" [routerLink]="[ '/contacts', row.contactID]">{{
              row.contactName }}</a>

            <ng-template #cnTtle>{{ row.contactName }}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="commitmentReasonName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> סיבה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סיבה" fxHide fxShow.gt-xs>
            {{row.commitmentReasonName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pulseAmount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> סכום חו' </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סכום חו'"><span *ngIf="row.pulseAmount">{{
              row.pulseAmount |
              number }} {{row.currencyIcon}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="globalAmount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> סכום כללי </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סכום כללי">
            <span *ngIf="row.globalAmount">{{row.globalAmount | number}} {{row.currencyIcon}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="qty">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> תש'
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תש'" fxHide fxShow.gt-xs>
            {{row.qty}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fromDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מתאריך
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מתאריך" fxHide fxShow.gt-xs>
            {{row.fromDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishFromDate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="payMethodID">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> באמצעות
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="באמצעות" fxHide fxShow.gt-xs>
            <div *ngIf="row.payMethodID">
              <a *ngIf="row.payMethodID != 88 && row.payMethodID != 89 && row.payMethodID != 420 && row.payMethodID != 1310 && row.payMethodID != 3580 && row.payMethodID != 6232 && row.payMethodID != 6990"
                [routerLink]="['/', {outlets: {popup: [ 'payMethods' , row.payMethodID]}}]">
                {{ row.methodTitle }}</a>
              <span *ngIf="row.payMethodID == 88">מזומן</span>
              <span *ngIf="row.payMethodID == 89">שיקים</span>
              <span *ngIf="row.payMethodID == 420">העברה בנקאית</span>
              <span *ngIf="row.payMethodID == 1310">קופה רושמת</span>
              <span *ngIf="row.payMethodID == 3580">Vouchers</span>
              <span *ngIf="row.payMethodID == 6232">תוכנה חיצונית</span>
              <span *ngIf="row.payMethodID == 6990">שווי כסף</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tillDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>תאריך סיום
            (מתוכנן)
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך סיום (מתוכנן)" fxHide fxShow.gt-xs>
            {{ row.tillDate | date:'dd/MM/yyyy' }} <br> {{ row.jewishTillDate}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="closedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>תאריך סגירת
            ההתחייבות
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך סגירת ההתחייבות" fxHide fxShow.gt-xs>
            {{ row.closedDate | date:'dd/MM/yyyy' }} <br> {{ row.jewishClosedDate}}
            <i *ngIf="row.closedStatus == 1" [matTooltip]="('נסגר ' +row.closedStatusName)" matSuffix
              style="color: rgb(148 241 0)" class="fa fa-check-circle-o">
            </i>
            <i *ngIf="row.closedStatus == 2" [matTooltip]="('נסגר ' +row.closedStatusName)" matSuffix
              style="color: #f4433673;" class="fa fa-times">
            </i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="closedStatusName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>סטטוס סיום
            התח'
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סטטוס סיום התח'" fxHide fxShow.gt-xs>
            {{ row.closedStatusName }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.commitmentID)">
        </mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>
  <div fxFlex="15">
    <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
      [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
    </mat-paginator>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
