import { map } from 'rxjs/operators';
import { Commitment } from './../../commitments/commitment/commitment';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PaymentsPlansData } from '../paymentsPlans.data';
import { PaymentsPlan, PayPlanCommitment, HoldingsSetting } from './paymentsPlan';
import { ServicesData } from '../../services/services.data';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsPlanPaymentsChangesDlg } from './paymentsPlan-payments-changes-dlg.cmp';

declare const Hebcal: any;

@Component({
  selector: 'paymentsPlan-edit',
  templateUrl: './paymentsPlan-edit.tmpl.html',
  styleUrls: ['./../paymentsPlans.scss'],
})

export class PaymentsPlanEdit implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    public paymentsPlansData: PaymentsPlansData,
    private formBuilder: FormBuilder,
    private servicesData: ServicesData,
    public router: Router,
    private location: LocationStrategy,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {

  }

  terms;
  paymentsPlanType;
  paymentsPlanTypeName: '';
  dayOptions: any[];
  jewishDayOptions: any[];
  paymentsPlan: PaymentsPlan;
  id: number;
  contactId: number;
  commitmentId: number;
  amountWay: string = 'pulseAmount';
  paymentsPlanForm: FormGroup;
  payOption = '';
  honors: any[];
  //subject: number;
  commitmentReasons;
  registrations;
  subjects;
  qtyDescription;
  currencyIcon = '';
  qty;
  monthlyPay;
  lastPay;
  unequalAmount = true; // מיועד לצורך עדכון ולידציה שהסכום אינו שווה
  payMethodID;
  public confirmationMessageBeforeSaving;
  public errorMsg;

  qtyCalculator() {
    let amount = +this.paymentsPlanForm.controls['globalAmount'].value || 0;

    if (!amount || amount === 0) {
      return;
    }

    if (+this.paymentsPlanForm.controls['currency'].value > 0) {
      let crncy = this.servicesData.Currencies[+this.paymentsPlanForm.controls['currency'].value - 1];
      this.currencyIcon = crncy ? crncy.name : '';
    }

    this.qty = +this.paymentsPlanForm.controls['qty'].value || null;
    if (!this.qty) {
      return;
    }

    this.monthlyPay = +(amount / this.qty);
    this.qtyDescription = `${this.lastPay > 0 ? this.qty - 1 : this.qty} תשלומים בסך ${this.monthlyPay}${this.currencyIcon}`;
    this.paymentsPlanForm.controls['pulseAmount'].setValue(this.monthlyPay);
  }





  load(): void {
    if (this.id > 0) {
      if (this.location.path().includes('addCopy')) {
        this.paymentsPlansData.getAddCopy(this.id || null)
          .subscribe((p: any) => {
            this.commitmentId = p.commitmentID;
            this.id = p.paymentsPlanID;
            this.fillForm(p);
            // if (p.holdingsSetting != null) {
            //   this.openHoldingsSetting();
            // }
          });

      } else {
        this.paymentsPlansData.getEdit(this.id || null)
          .subscribe((p: any) => {
            this.commitmentId = p.commitmentID;
            this.fillForm(p);
            if (p.holdingsSetting != null) {
              this.openHoldingsSetting(p.holdingsSetting);
            }
            if (this.id > 0) {
              this.confirmationMessageBeforeSaving = "שים לב, רשומות תשלומים שנערכו/שונו ישארו במקומם ולא ירועננו בהתאם לפרטי העריכה העדכניים\nעליך לעדכן אותם באופן פרטי לאחמ'כ\nהאם להמשיך בכל זאת?";
            }

          });
      }

    } else {
      this.paymentsPlansData.getAdd(this.commitmentId, this.contactId, this.paymentsPlanType)
        .subscribe((p: any) => {
          this.fillForm(p);
        });
    }

  }

  public selectPayOption(event?: any) {
    let fpmID = +this.paymentsPlanForm.controls['payMethodID'].value;
    this.setPayOption(fpmID);
    this.cdRef.detectChanges();
  }

  private fillForm(p: PaymentsPlan): void {
    this.paymentsPlan = p;
    if (!this.contactId) {
      this.contactId = p.contactID || 0;
    }
    if (p.paymentsPlanID == 0 && !p.pulseAmount) {
      p.pulseAmount = null;
    }

    this.amountWay = this.paymentsPlan.globalAmount ? 'globalAmount' : 'pulseAmount';

    this.paymentsPlanForm.patchValue(p);



    const payPlanCommitmentsControl = <FormArray>this.paymentsPlanForm.controls['payPlanCommitments'];
    if ((<any>p).payPlanCommitments) {
      let openNewPayPlanCommitment = this.id == 0 && (<any>p).payPlanCommitments.length == 1 && (<any>p).payPlanCommitments[0].commitmentID == 0; //כאשר התשלום חדש ההתחייבות האוטומטית היא כללי
      (<any>p).payPlanCommitments.forEach(element => {
        let pcForm = this.initPayPlanCommitment(element, openNewPayPlanCommitment);
        payPlanCommitmentsControl.push(pcForm);
      });
    }


    this.selectPayOption();
    this.loadTerms();
  }


  selectContactCommitment(e) {
    let ix = e.target.selectedIndex;
    let commitment = this.contactCommitments[ix];
    if (commitment) {
      this.addPayPlanCommitmentControl(commitment.commitmentID, false, commitment);
    }

  }

  addPayPlanCommitmentControl(id: number, openNewPayPlanCommitment: boolean = false, commitment: any = null) {
    let commitmentID = id || 0;
    const payPlanCommitmentsControl = (<FormArray>this.paymentsPlanForm.controls['payPlanCommitments']);

    //partialPulseAmount
    let amount = +this.paymentsPlanForm.controls['pulseAmount'].value || 0;
    let sumAmount = 0;
    let specialtyAmount = +this.paymentsPlanForm.controls['specialtyMonthsAmount'].value || 0;
    let sumSpecialtyAmount = 0;

    payPlanCommitmentsControl.controls.forEach((x: FormGroup) => {
      sumAmount += parseFloat(x.controls['partialPulseAmount'].value || 0);
      sumSpecialtyAmount += parseFloat(x.controls['partialSpecialtyMonthsAmount'].value || 0);
    });

    let partialPulseAmount = (amount - sumAmount);
    partialPulseAmount = partialPulseAmount > 0 ? partialPulseAmount : 0;

    let partialSpecialtyMonthsAmount = (specialtyAmount - sumSpecialtyAmount);
    partialSpecialtyMonthsAmount = partialSpecialtyMonthsAmount > 0 ? partialSpecialtyMonthsAmount : 0;

    let ppc = new PayPlanCommitment();
    ppc.commitmentID = commitmentID;
    ppc.partialPulseAmount = partialPulseAmount;
    ppc.partialSpecialtyMonthsAmount = partialSpecialtyMonthsAmount;
    ppc.paymentsPlanID = this.id;
    ppc.contactID = this.contactId;

    if (!commitment) {
      commitment = new Commitment();
    }

    ppc.commitmentReasonID = commitment.commitmentReasonID;
    ppc.commitmentReasonName = commitment.commitmentReasonName;
    ppc.commitmentSubject = commitment.commitmentSubject;
    ppc.commitmentSubjectName = commitment.subjectName;
    ppc.designation = commitment.designation;
    ppc.designationDetails = commitment.designationDetails;


    let ppcForm = this.initPayPlanCommitment(ppc, openNewPayPlanCommitment);
    payPlanCommitmentsControl.push(ppcForm);
  }

  calculatePayPlanCommitmentsAmounts() {

    const payPlanCommitmentsControl = (<FormArray>this.paymentsPlanForm.controls['payPlanCommitments']);
    let amount = +this.paymentsPlanForm.controls['pulseAmount'].value || 0;
    let specialtyAmount = +this.paymentsPlanForm.controls['specialtyMonthsAmount'].value || 0;

    let cnt = payPlanCommitmentsControl.controls.length;

    if (amount > 0 && cnt >= 1) {
      payPlanCommitmentsControl.controls.forEach((x: FormGroup) => {
        x.controls['partialPulseAmount'].setValue(amount / cnt);
        if (specialtyAmount > 0) {
          x.controls['partialSpecialtyMonthsAmount'].setValue(specialtyAmount / cnt);
        }
      });
    }
  }

  initPayPlanCommitment(ppc: any, openNewPayPlanCommitment: boolean = false) {

    if (openNewPayPlanCommitment && !ppc.hebYear) {
      let dt = new Date();
      let hebcal = Hebcal;
      ppc.hebYear = hebcal.gematriya(hebcal.HDate(dt.getFullYear() - 1, dt.getMonth()).year - 1, 3).replace('"', ''); //TODO minus 1 ?????
    }

    let payPlanCommitmentFormGroup = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      payPlanCommitmentID: [ppc.payPlanCommitmentID || 0, Validators.required],
      paymentsPlanID: [ppc.paymentsPlanID || 0, Validators.required],
      paymentID: [ppc.paymentID || 0, Validators.required],
      contactID: [ppc.contactID || this.contactId, Validators.required],
      partialPulseAmount: [ppc.partialPulseAmount || null, Validators.required],
      partialSpecialtyMonthsAmount: [ppc.partialSpecialtyMonthsAmount || null],
      partialValueInCommitment: [ppc.partialValueInCommitment],
      lockPartialValueInCommitment: [ppc.lockPartialValueInCommitment || false, Validators.required],
      commitmentID: [ppc.commitmentID, Validators.required],
      commitmentSubject: [ppc.commitmentSubject, openNewPayPlanCommitment ? Validators.required : null],
      commitmentReasonID: [ppc.commitmentReasonID], //, openNewPayPlanCommitment ? Validators.required : null],
      designation: [ppc.designation],
      designationDetails: [ppc.designationDetails],
      hebYear: [ppc.hebYear],

      //trick
      openNewPayPlanCommitment: [openNewPayPlanCommitment]
    });

    payPlanCommitmentFormGroup.controls['commitmentSubject'].valueChanges.subscribe(() => {
      this.loadTerms();
    });

    return payPlanCommitmentFormGroup;
  }

  specialTypeSelected = false;
  selectType(e) {
    if (e) {
      this.paymentsPlanTypeName = e.source.triggerValue;
    }
  }
  selectPaymentType(type) {
    this.specialTypeSelected = (type > 3);
  }

  payMethodsAddEvent(payMethod) {
    this.paymentsPlanForm.controls['payMethodID'].setValue(payMethod.id);
    this.paymentsPlanForm.controls['needRefreshFuturePayments'].setValue(true);
    this.setPayOption(payMethod.id, payMethod.type);
    this.cdRef.detectChanges();
  }
  payMethodChangeEvent(payMethod) {
    this.paymentsPlanForm.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.paymentsPlanForm.controls['needRefreshFuturePayments'].setValue(true);
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
    this.cdRef.detectChanges();
  }

  payMethodSelection(payMethod) {
    console.log("payMethod", payMethod);

    if (payMethod.amountLimit) {
      this.payMethodAmountNotification = `שים לב! קיימת מגבלה לאמצעי תשלום זה עד לסכום ${payMethod.amountLimit}`;
    } else {
      this.payMethodAmountNotification = '';
    }
    if (payMethod.dateLimit) {
      this.payMethodDateNotification = `שים לב! קיימת מגבלה לאמצעי תשלום זה עד לתאריך ${payMethod.dateLimit}`;
    } else {
      this.payMethodDateNotification = '';
    }
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
    this.cdRef.detectChanges();
  }

  payMethodAmountNotification = '';
  payMethodDateNotification = '';

  loadTerms() {
    const payPlanCommitmentsControl = <FormArray>this.paymentsPlanForm.controls['payPlanCommitments'];
    this.subjects = <any>payPlanCommitmentsControl.controls.map(x => (<any>x).controls.commitmentSubject.value).join();
    if (this.contactId && this.subjects && this.subjects != '') {
      this.servicesData.getList(`SubjectTermsAndDefault/${this.contactId}/${this.subjects}`)
        .subscribe((x: any) => {
          let dtid = this.paymentsPlanForm.controls['defaultTermID'].value;
          if (this.id == 0 || !dtid) {
            if (x.value.length == 1) {
              this.paymentsPlanForm.controls['defaultTermID'].setValue(x.value[0].id);
            } else if (dtid) {
              this.paymentsPlanForm.controls['defaultTermID'].setValue(dtid);
            } else {
                this.paymentsPlanForm.controls['defaultTermID'].setValue(x.key);
            }
          }
          this.terms = x.value;
          if (dtid && x.value && x.value.map(y => y.id).indexOf(dtid) == -1) {
            this.paymentsPlanForm.controls['defaultTermID'].setValue(null);
          }
        });

    }
  }

  termIDValidation = c => {
    if ((!c.value || c.value === 0 || c.value === 'undefined') && [88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(this.payMethodID) == -1) {
      return { required: true };
    }
    return null;
  };


  currencyValidation = c => {
    if (this.paymentsPlanForm) {
      let currencyCtrl = this.paymentsPlanForm.controls['currency'];
      if (!c.value || !currencyCtrl || currencyCtrl.value <= 0) {
        return { required: true };
      }
    }
    return null;
  };


  qtyValidation = c => {
    if (this.paymentsPlanForm) {
      if (!c.value && this.paymentsPlanForm.controls['globalAmount'].value) {
        return { required: true };
      }
    }
    return null;
  };



  setPayOption(payMethodID: number, payMethodOption: string = null) {
    if ([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(payMethodID) == -1) {
      switch (payMethodOption) {
        case 'אשראי':
          this.payOption = 'cc';
          this.loadTerms();
          break;
        case 'הוראת קבע':
          this.payOption = 'hok';
          break;
        default:
          this.terms = null;
          break;
      }
    }
    else if (payMethodID == 88) {
      this.payOption = 'Cash';
    }
    else if (payMethodID == 89) {
      this.payOption = 'Check';
    }
    else if (payMethodID == 420) {
      this.payOption = 'BankTransfer';
    }
    else if (payMethodID == 1310) {
      this.payOption = 'cashRegister';
    }
    else if (payMethodID == 3580) {
      this.payOption = 'voucher';
    }
    else if (payMethodID == 6232) {
      this.payOption = 'externalSoftware';
    }
    else if (payMethodID == 6990) {
      this.payOption = 'moneyValue';
    }
    else if (!payMethodID || payMethodID == 0) {
      this.payOption = '';
    }
  }






  paymentsPlanFormValidation = c => {
    this.unequalAmount = true;
    let sumAmount = 0;
    let sumspecialtyAmount = 0;
    const payPlanCommitmentsControl = <FormArray>this.paymentsPlanForm.controls['payPlanCommitments'];

    payPlanCommitmentsControl.controls.forEach((x: FormGroup) => {
      sumAmount += parseFloat(x.controls['partialPulseAmount'].value || 0);
      sumspecialtyAmount += parseFloat(x.controls['partialSpecialtyMonthsAmount'].value || 0);
    });

    if (this.id == 0 && payPlanCommitmentsControl.length <= 1 && c.controls['pulseAmount'].value == null) {
      return { required: true };
    }

    if (payPlanCommitmentsControl.length > 0 && (+c.controls['pulseAmount'].value !== sumAmount) || (+c.controls['specialtyMonthsAmount'].value !== sumspecialtyAmount)) {
      this.unequalAmount = false;
      return { required: true };
    }

    if (payPlanCommitmentsControl.length === 0) {
      return { required: true };
    }



    payPlanCommitmentsControl.controls.forEach((x: FormGroup) => {
      let testAmount = parseFloat(x.controls['partialPulseAmount'].value || 0);
      if (testAmount == 0) {
        this.unequalAmount = false; // כאשר הסכום באחד הרשומות = 0
        return { required: true };
      }
    });

    return null;
  };


  reloadSubjectTermsEvent(obj) {
    this.paymentsPlanForm.controls['needRefreshFuturePayments'].setValue(true);
    const pcControl = <FormGroup>(<FormArray>this.paymentsPlanForm.controls['payPlanCommitments']).controls[obj.ix];
    pcControl.controls['commitmentSubject'].setValue(obj.subject);
    this.loadTerms();
  }

  removeHoldingsSetting() {
    this.paymentsPlanForm.removeControl('holdingsSetting');
    //this.paymentsPlanForm.controls['holdingsSettingID'].setValue(null);
  }
  createNewHoldingsSetting() {
    //בהמשך לעשות שאם יש תוכנית שהרגע נמחקה כלומר שהמזהה שלה עדיין נמצא לאחזר את הנתונים שלה מהשרת ולא למוחקה

    let holdingsSetting = new HoldingsSetting();
    holdingsSetting.holdingsSettingID = this.paymentsPlan.holdingsSettingID;
    holdingsSetting.paymentsPlanID = this.id;
    holdingsSetting.contactID = this.paymentsPlan.contactID;
    this.openHoldingsSetting(holdingsSetting);
  }

  openHoldingsSetting(hs) {
    this.paymentsPlanForm.addControl('holdingsSetting', this.formBuilder.group({
      createBy: [hs?.createBy], createDate: [hs?.createDate], changeBy: [hs?.changeBy], changeDate: [hs?.changeDate],
      holdingsSettingID: [hs?.holdingsSettingID || 0, Validators.required],
      paymentsPlanID: [hs?.paymentsPlanID],
      holdingFromMonth: [hs?.holdingFromMonth],
      holdingFromYear: [hs?.holdingFromYear],
      holdingAmount: [hs?.holdingAmount],
      holdingSpecialtyMonthsAmount: [hs?.holdingSpecialtyMonthsAmount],
      holdingCurrency: [hs?.holdingCurrency],
      holdingContactIsDied: [hs?.holdingContactIsDied || false, Validators.required],
      lastHoldingRecordOn: [hs?.lastHoldingRecordOn],
      contactID: [hs?.contactID],
      contactName: [hs?.contactName],
      holdingContactID: [hs?.holdingContactID],
      holdingContactName: [hs?.holdingContactName],
      holdingContactRelationTypeID: [hs?.holdingContactRelationTypeID],
      holdingContactRelationTypeName: [hs?.holdingContactRelationTypeName],
      reOldHoldings: [hs?.reOldHoldings || false, Validators.required],
      byMonthlyPayments: [hs?.byMonthlyPayments || true, Validators.required],

    }));
    //  let hssttngs = this.paymentsPlanForm.controls['holdingsSetting'];
    // if (hssttngs.value == null) {
    //   this.initholdingsSettingForm();
    // }
  }
  // initholdingsSettingForm() {
  //   this.paymentsPlanForm.controls['holdingsSetting'] = this.formBuilder.group({
  //     createBy: [], createDate: [], changeBy: [], changeDate: [],
  //     holdingsSettingID: [0, Validators.required],
  //     paymentsPlanID: [],
  //     holdingFromMonth: [],
  //     holdingFromYear: [],
  //     holdingAmount: [],
  //     holdingSpecialtyMonthsAmount: [],
  //     holdingCurrency: [],
  //     holdingContactIsDied: [false, Validators.required],
  //     lastHoldingRecordOn: [],
  //     contactID: [],
  //     contactName: [],
  //     holdingContactID: [],
  //     holdingContactName: [],
  //     holdingContactRelationTypeID: [],
  //     holdingContactRelationTypeName: [],
  //     reOldHoldings: [false, Validators.required],

  //   });
  //   //   hssttngs.setValue(new HoldingsSetting());
  //   this.cdRef.detectChanges();


  // }

  private initForm() {
    this.paymentsPlanForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      paymentsPlanID: [this.id, Validators.required],
      contactID: [this.contactId, Validators.required],
      pladaHorkID: [],
      paymentsPlanType: [this.paymentsPlanType || 1, Validators.required],
      pulseAmount: [null, Validators.required],
      specialtyMonthsAmount: [null],
      globalAmount: [null],
      currency: [null, this.currencyValidation],
      payMethodID: [, Validators.required],
      fromDate: [, Validators.required],
      hebrewDate: [true, Validators.required],
      dayOfMonth: [],
      repeats: [],
      qty: [null, this.qtyValidation],
      tillDate: [],
      closedDate: [],
      closedBy: [],
      closedStatus: [],
      closedComment: [],
      defaultReceiptAddressID: [],
      defaultContactTitleForReceipts: [],
      defaultDetailsLetterAsReceipt: [],
      comment: [],
      defaultTermID: [, Validators.required], //, this.termIDValidation
      holdingsSettingID: [],
      payPlanCommitments: this.formBuilder.array([]),
      //tricks
      needRefreshFuturePayments: [false]
    });


    this.paymentsPlanForm.setValidators(this.paymentsPlanFormValidation);

    this.paymentsPlanForm.controls['qty'].valueChanges.subscribe(() => this.qtyCalculator());


    this.paymentsPlanForm.controls['paymentsPlanType'].valueChanges.subscribe(x => this.selectPaymentType(x));



    this.paymentsPlanForm.controls['globalAmount'].valueChanges.subscribe(x => {
      if (x) {
        this.paymentsPlanForm.controls['pulseAmount'].setValue(null);
        this.paymentsPlanForm.controls['specialtyMonthsAmount'].setValue(null);
        this.qtyCalculator();
      }
    });
    this.paymentsPlanForm.controls['pulseAmount'].valueChanges.subscribe(x => {
      this.updatePayPlanPartialPulseAmount(x);
    });
    this.paymentsPlanForm.controls['specialtyMonthsAmount'].valueChanges.subscribe(x => {
      this.updatePayPlanPartialSpecialtyMonthsAmount(x);
    });
  }


  updatePayPlanPartialPulseAmount(amnt) {
    const payPlanCommitmentsControl = <FormArray>this.paymentsPlanForm.controls['payPlanCommitments'];
    if (payPlanCommitmentsControl.length === 1 && this.id == 0) {
      let val = amnt ? +amnt : null;
      (<any>payPlanCommitmentsControl.controls[0]).controls['partialPulseAmount'].setValue(val);
    }
  }

  updatePayPlanPartialSpecialtyMonthsAmount(amnt) {
    const payPlanCommitmentsControl = <FormArray>this.paymentsPlanForm.controls['payPlanCommitments'];
    if (payPlanCommitmentsControl.length === 1 && this.id == 0) {
      let val = amnt ? +amnt : null;
      (<any>payPlanCommitmentsControl.controls[0]).controls['partialSpecialtyMonthsAmount'].setValue(val);
    }
  }

  loadContactCommitments = false;
  contactCommitments;
  includeIsFundraisings = false;
  public getContactCommitments(): void {
    this.loadContactCommitments = true;
    this.servicesData.getList(`ContactCommitments/${this.contactId}/${this.includeIsFundraisings}`).subscribe(
      c => {
        this.contactCommitments = c;
        this.loadContactCommitments = false;
      },
      () => {
        this.loadContactCommitments = false;
      });
  }

  showContactCommitments() {
    this.getContactCommitments();
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['paymentsPlanID'] || 0;
      this.commitmentId = +params['commitmentID'] || null;
      this.contactId = +params['contactID'] || null;
      this.paymentsPlanType = +params['paymentsPlanType'] || null;
    });

    this.initForm();
    this.load();

    //לאחר טעינת המודל
    this.paymentsPlanForm.controls['payMethodID'].valueChanges.subscribe(x => {
      this.payMethodID = x;
      this.loadTerms();
    });

    this.jewishDayOptions = this.servicesData.JewishDayOptions;
    this.dayOptions = this.servicesData.DayOptions;


  }

  saveChanges(form: FormGroup) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(PaymentsPlanPaymentsChangesDlg, {
        data: this.id
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          console.log(result);
        }
      });
    }, 200);
  }

}



